@font-face {
  font-family: ProductSans;
  src: url("./fonts/ProductSans-Regular.woff2") format("woff2");
  font-style: normal;
  font-weight: normal;
  font-display: fallback;
}

@font-face {
  font-family: Gilroy;
  src: url("./fonts/Gilroy-Regular.woff2") format("woff2");
  font-style: normal;
  font-weight: normal;
  font-display: fallback;
}
