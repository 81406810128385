@tailwind base;
@tailwind components;
@tailwind utilities;

@layer components {
  .tooltip-container .tooltip-lg {
    @apply w-520 leading-tight text-18 bg-[#8283DA] text-white py-32 px-30;
  }

  .tooltip-container .tooltip-sm {
	@apply span-w-6 mx-auto leading-tight text-14 text-white bg-[#8283DA] py-16 px-16;
  }

  .tooltip-container .carousel-tooltip-lg {
	@apply w-520 leading-tight text-18 text-white py-32 px-30;
  }

  .tooltip-container .carousel-tooltip-sm {
	@apply span-w-6 mx-auto leading-tight text-14 text-white py-16 px-16 bg-[#8283DA];
  }
}

@layer base {
	* {
		-webkit-tap-highlight-color: transparent;
		@apply antialiased
	}

	body {
		@apply overscroll-none w-full;
		@apply lg:overflow-hidden;
	}

	body:has(.noscroll) {
		@apply overflow-hidden;
	}
	
	p,
	h1, h2, h3, h4, h5, h6 {
		@apply whitespace-pre-wrap;
	}
}

:root {
	--headerHeight: 5.1875rem;

	--screen: 100vh;
	--screen-full: 100vh;
	--screen-dynamic: 100vh;
	--screen-width: 100vw;
}

@supports (height: 100svh) {
	:root {
		--screen: 100svh;
	}
}

@supports (height: 100lvh) {
	:root {
		--screen-full: 100lvh;
	}
}

@supports (height: 100dvh) {
	:root {
		--screen-dynamic: 100dvh;
	}
}

@media screen(lg) {
	:root {
		--headerHeight: 4.625rem;
		--footerHeight: 1.75rem;
	}
}

.lenis.lenis-smooth [data-lenis-prevent] {
	@apply overscroll-contain;
}

.lenis.lenis-stopped {
	@apply overflow-clip;
	/* padding-right: var(--sbw, 0px); */
}

.lenis.lenis-smooth.lenis-scrolling {
	pointer-events: none;
}

 .definition-item {
	position: relative;
 }

.definition-item::before {
	content: '';
	width: 0px;
	display: inline-block;
	height: 100%;
	position: absolute;
	left: 50%;
	transform: translateX(-50%);
	border-right: 1px solid #E7E7E7;
}

 .timeline-item {
	position: relative;
 }
 .timeline-item::before {
	content: '';
	width: 0px;
	display: inline-block;
	height: 100%;
	position: absolute;
	left: 50%;
	transform: translateX(-50%);
	border-right: 2px dashed rgba(46, 46, 46, 0.3);
}

.timeline-item--modified::before{
	height: 0%;
}
 
 @media screen and (max-width: 768px) {
	.timeline-item::before {
		 left: 50px;
		 display: none;
	}
	.definition-item::before {
	display: none;}
}

.progress-bar--showtext{
	color: #00BEA9;
}

.progress-bar--showcircle{
	background: #00BEA9;
}

.active{
	display: block;
	color: #00BEA9;
}


  @keyframes hero-bg {
  0% {
    opacity: 0;
    transform: translateY(10%);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
	visibility: visible;
	}
}

  @keyframes hero-nav {
  0% {
    opacity: 0;
    transform: translateY(-10%);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
	visibility: visible;
	}
}

.animate-hero-bg {
  animation: hero-bg 2000ms cubic-bezier(0.645, 0.045, 0.355, 1) forwards
}

.animate-hero-nav {
  animation: hero-nav 2000ms cubic-bezier(0.645, 0.045, 0.355, 1) forwards
}

.burger{
    width: 100%;
}

.burger::after{
	top:-5px;
}
.burger::before{
	top:5px;
}

.burger::after, 
.burger::before {
	content: "";
	display: block;
	height: 1px;
	width: 40%;
	margin: auto;
	background-color: white;
	position: relative;
	transition: transform 0.3s;
}

.burgerActive::after{
	transform: rotate(45deg);
	top: -1px;
}

.burgerActive::before{
	transform: rotate(-45deg);
	top: 0px;
}

