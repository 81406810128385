.token {
	transform: translate3d(0, 110%, 0);
	transition: transform var(--reveal-duration, 2s) var(--reveal-easing, theme('transitionTimingFunction.expo-out'));
	opacity: 0;
}
  
.active .token {
	opacity: 1;
	transform: none;
	transition-delay: calc((var(--reveal-delay, 0) + (var(--reveal-token-idx, 0) * var(--reveal-stagger, 0))) * 1s);
}